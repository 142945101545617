<template>
  <div id="flood" class="position-relative">
    <spinner v-if="isLoading" />
    <div id="map"></div>
    <button
      id="map-search-toggle"
      class="btn btn-dark d-inline-block d-md-none"
      @click="showSearch = true"
    >
      <i class="fas fa-search text-light"></i>
    </button>
    <div
      id="map-search"
      class="position-absolute top-0 py-3 pe-3 ps-4"
      :class="{ show: showSearch }"
    >
      <div id="map-search-content" class="rounded">
        <div id="map-search-input" class="p-2 bg-default-dark shadow rounded">
          <div class="input-group overflow-hidden">
            <span class="input-group-text bg-default-dark border-0">
              <i class="fas fa-search text-light"></i>
            </span>
            <input
              type="text"
              class="form-control bg-default-dark text-light border-0"
              placeholder="Search a road"
              v-model="query"
            />
            <button
              class="btn btn-dark rounded d-inline-block d-md-none"
              type="button"
              @click="showSearch = false"
            >
              <i class="fas fa-times text-light"></i>
            </button>
          </div>
        </div>
        <div
          id="map-search-list"
          class="list-group list-group-flush rounded mt-2"
        >
          <div
            class="list-group-item bg-dark p-0"
            v-for="(district, index) in districts"
            :key="`district${index}${district}`"
          >
            <div
              class="
                bg-default-dark
                small
                text-light text-uppercase
                fw-bold
                px-3
                py-2
                position-sticky
                top-0
                start-0
              "
              style="z-index: 100"
              v-if="
                districts.length > 1 &&
                features.filter(
                  (feature) => feature.properties.district == district
                ).length > 0
              "
            >
              {{ district }}
            </div>
            <button
              v-for="(feature, index) in features.filter(
                (feature) => feature.properties.district == district
              )"
              :key="`feature${index}`"
              class="
                list-group-item list-group-item-action
                border-default-dark
                py-3
                bg-dark
                text-light text-capitalize
              "
              :class="{
                selected:
                  selectedFeature &&
                  selectedFeature.properties.name === feature.properties.name,
              }"
              @click="selectFeature(feature)"
            >
              <span
                :style="statusStyle(feature)"
                class="badge rounded-pill px-2 me-2"
              >
                {{ status(feature) }}
              </span>
              {{ getFeaturesName(feature.properties) }}
            </button>
          </div>
          <div
            v-if="features.length == 0"
            class="
              bg-default-dark
              p-2
              text-center
              h-100
              text-muted
              d-flex
              justify-content-center
              align-items-center
            "
          >
            No road found
          </div>
        </div>
        <div id="map-legend" class="rounded small p-2 bg-default-dark mt-2">
          <p class="pb-2 mb-2 text-muted border-bottom border-dark">LEGEND</p>
          <p>
            <span
              class="badge rounded-pill px-2 me-2"
              :style="{ 'background-color': 'red' }"
            >
              T
            </span>
            <span><strong>DITUTUP</strong>&nbsp;kepada semua kenderaan</span>
          </p>
          <p>
            <span
              class="badge rounded-pill px-2 me-2"
              :style="{ 'background-color': 'green' }"
            >
              Dibuka
            </span>
            <span> <strong>DIBUKA</strong>&nbsp;kepada semua kenderaan </span>
          </p>
          <p>
            <span
              class="badge rounded-pill px-2 me-2"
              :style="{ 'background-color': '#c59100' }"
            >
              B1
            </span>
            <span>
              Jalan&nbsp;<strong>DIBUKA</strong>&nbsp;kepada
              kenderaan&nbsp;<strong>BERAT</strong>
            </span>
          </p>
          <p>
            <span
              class="badge rounded-pill px-2 me-2"
              :style="{ 'background-color': '#c59100' }"
            >
              B2
            </span>
            <span>
              Jalan&nbsp;<strong>DIBUKA</strong>&nbsp;kepada
              kenderaan&nbsp;<strong>RINGAN</strong>
            </span>
          </p>
          <p>
            <span
              class="badge rounded-pill px-2 me-2"
              :style="{ 'background-color': '#c59100' }"
            >
              B3
            </span>
            <span>
              Jalan&nbsp;<strong>DIBUKA</strong>&nbsp;satu lorong sahaja
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="position-absolute top-0 end-0 p-3">
      <button
        type="button"
        class="btn btn-dark mx-1 shadow"
        @click="toggleMapStyle(!selectedMapStyle)"
      >
        <i
          :class="`small ${
            !selectedMapStyle ? 'fas fa-satellite' : 'fas fa-road'
          }`"
        />
        <span class="ms-2 d-none d-sm-inline">
          {{ !selectedMapStyle ? "Satellite" : "Street" }}
        </span>
      </button>
      <button
        :disabled="!pdfFile"
        class="btn btn-dark mx-1 shadow"
        @click="handleViewPDF"
      >
        <i class="small fas fa-file-pdf" />
        <span class="ms-2 d-none d-sm-inline">View Full Report</span>
      </button>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import Spinner from "@/components/Spinner";
import YAML from "yaml";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      map: null,
      selectedMapStyle: false,
      selectedFeature: null,
      geojson: null,
      query: "",
      pdfFile: null,
      showSearch: false,
      districts: [],
    };
  },
  watch: {
    selectedFeature() {
      if (this.selectedFeature === null || this.map === null) return;

      const bounds = new mapboxgl.LngLatBounds();

      this.selectedFeature.geometry.coordinates.forEach((coordinate) => {
        bounds.extend([coordinate[0], coordinate[1]]);
      });

      this.map.fitBounds(bounds, {
        padding: 25,
        easing: (t) => {
          return t;
        },
      });
    },
  },
  computed: {
    features() {
      let result =
        this.geojson && this.geojson.features
          ? JSON.parse(JSON.stringify(this.geojson.features))
          : [];

      if (this.query !== "") {
        result = result.filter((feature) =>
          this.getFeaturesName(feature.properties).includes(
            this.query.toLowerCase()
          )
        );
      }

      return result;
    },
  },
  methods: {
    selectFeature(feature) {
      this.selectedFeature = feature;

      this.showSearch = false;
    },
    getFeaturesName(properties) {
      if (!properties) {
        return "";
      }

      if (properties.name) {
        return properties.name.toLowerCase();
      } else if (properties.Name) {
        return properties.Name.toLowerCase();
      } else {
        return "";
      }
    },
    handleViewPDF() {
      if (this.pdfFile !== null && this.pdfFile !== undefined) {
        window.open(this.pdfFile);
      }
    },
    toggleMapStyle(style) {
      this.selectedMapStyle = style;

      const satelliteStyle = "mapbox://styles/mapbox/satellite-v9";

      const defaultStyle =
        "mapbox://styles/izadchemuda2/cks9yhbea9zlu17q6e9looady";

      this.map.setStyle(style ? satelliteStyle : defaultStyle);
    },
    getFeaturesDistrict(feature) {
      if (feature && feature.properties && feature.properties.description) {
        const desc = YAML.parse(
          feature.properties.description.replace(/  +/g, "")
        );

        if (typeof desc.Daerah !== "undefined") {
          return desc.Daerah;
        } else {
          return "n/a";
        }
      } else {
        return "n/a";
      }
    },
    status(feature) {
      if (feature && feature.properties && feature.properties.description) {
        const desc = YAML.parse(
          feature.properties.description.replace(/  +/g, "")
        );

        if (typeof desc.Status !== "undefined") {
          return desc.Status;
        } else {
          return "n/a";
        }
      } else {
        return "n/a";
      }
    },
    statusStyle(feature) {
      if (feature && feature.properties && feature.properties.description) {
        const desc = YAML.parse(
          feature.properties.description.replace(/  +/g, "")
        );

        if (typeof desc.Status === "undefined") {
          return { "background-color": "#fff" };
        } else if (desc.Status === "T") {
          return { "background-color": "red" };
        } else if (desc.Status === "DIBUKA") {
          return { "background-color": "green" };
        } else if (["B1", "B2", "B3"].includes(desc.Status)) {
          return { "background-color": "#c59100" };
        } else {
          return { "background-color": "#fff" };
        }
      } else {
        return { "background-color": "#fff" };
      }
    },
    async loadGeojson() {
      const res = await this.API.get("geojsons?_sort=createdAt:DESC&_limit=1");

      if (
        res &&
        res.data &&
        res.data.length > 0 &&
        res.data[0].supportingFile &&
        res.data[0].supportingFile.url
      ) {
        this.pdfFile = res.data[0].supportingFile.url;
      }

      const res2 = await this.API.getExternal(res.data[0].file.url);

      this.geojson = res2.data;

      if (
        this.geojson &&
        this.geojson.features &&
        this.geojson.features.length > 0
      ) {
        this.geojson.features.forEach((feature, index) => {
          const style = this.statusStyle(feature);

          const district = this.getFeaturesDistrict(feature);

          this.geojson.features[index].properties.stroke =
            style["background-color"];

          this.geojson.features[index].properties.district = district;

          if (!this.districts.find((dis) => dis == district)) {
            this.districts.push(district);
          }

          this.districts.sort();
        });

        this.map.addSource("flood", {
          type: "geojson",
          data: this.geojson,
        });

        this.map.addLayer({
          id: "flood",
          type: "line",
          source: "flood",
          layout: {
            "line-join": "bevel",
            "line-cap": "square",
          },
          paint: {
            "line-color": ["get", "stroke"],
            "line-width": 5,
            "line-opacity": ["get", "stroke-opacity"],
            "line-opacity": 0.5,
          },
        });

        const bounds = new mapboxgl.LngLatBounds();

        this.features.forEach((feature) => {
          feature.geometry.coordinates.forEach((coordinate) => {
            bounds.extend([coordinate[0], coordinate[1]]);
          });
        });

        this.map.fitBounds(bounds, {
          padding: 25,
          easing: (t) => {
            return t;
          },
        });
      }
    },
    async initMap() {
      this.isLoading = true;

      return new Promise((resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/izadchemuda2/cks9yhbea9zlu17q6e9looady",
          center: [101.414161, 3.320954],
          zoom: 9,
        });

        this.map.on("style.load", async () => {
          await this.loadGeojson();

          this.isLoading = false;

          resolve();
        });
      });
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>