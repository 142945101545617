<template>
  <div class="loading-spinner">
    <div class="spinner-grow text-danger" role="status"></div>
    <p class="mb-0 mt-2 text-light">{{ text || "Loading..." }}</p>
  </div>
</template>

<script>
export default {
  name: "spinner",
  props: ["text"],
  mounted() {
    document.body.classList.add("overflow-hidden");
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-hidden");
  },
};
</script>

<style scoped>
.loading-spinner {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000d0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1031;
}
</style>